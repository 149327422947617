@use '@angular/material' as mat;

mat-accordion {
  color: mat.get-color-from-palette($ui-theme-primary, 500);
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      color: mat.get-color-from-palette($ui-theme-primary, 500);
      .mat-expansion-indicator {
      }
      .mat-content {
      }
    }
  }
}

.mat-form-field {
  &.white-bg{
    background-color: rgba(255, 255, 255, .18);
    border-radius: 2px;
    height: 24px;
    padding: 0 5px;
    .mat-form-field-flex {
      align-items: center;
    }
    .mat-form-field-label{
      color: rgba(255, 255, 255, 0.5);
    }
    .mat-input-element{
      color: #fff;
      line-height: 24px;
      caret-color: #fff;
    }
    .icon-placeholder{
      width: 15px;
      display: inline-block;
      i{
        cursor: pointer;
      }
    }
    .mat-icon{
      color: #f6f6f6;
      line-height: 1.4;
    }
  }

  label {
  }
  &.no-underline{
    .mat-form-field-underline {
      display: none !important;
    }
  }
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-labeler {
        }
        .mat-form-field-labelwrapper {
          .mat-form-field-label {
          }
        }
      }
    }
  }
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-infix {
          }
        }
      }
      .mat-form-field-infix {
      }
      input.mat-input-element {

      }
    }
    .mat-form-field-underline {
      .mat-form-field-ripple {
        background-color: #493C70;
      }
    }
  }
  &.mat-focused .mat-form-field-label {
    color: #493C70;
  }
}

mat-select {
  line-height: 2.5;

  .mat-select-value {
    color: mat.get-contrast-color-from-palette($ui-theme-primary, 400);
  }
  background-color: $mat-select-bg-color;
}

input:-webkit-autofill {
}

.custom-tooltip{
  white-space: pre-line;
  background: #0c2252;
  font-size: 16px;
  padding: 22px;
  line-height: 26px;
  color: white;
  border-radius: 4px;
}

.campaign-action-tooltip {
  background: #0c2252;
  width: 288px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #ffffff;
  padding: 22px;
}

.programs-action-tooltip {
  background: #0c2252;
  max-width: unset !important;
  max-height: unset !important;
  text-overflow: initial;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #ffffff;
  padding: 14px 16px 16px !important;
  border-radius: 4px;
  white-space: pre-line;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.pause-tooltip {
  height: 150px;
}

.campaign-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  background: #0c2252;
  padding: 10px;
  overflow: visible;
}

.desktop-user-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  background: #2A2E3B;
  color: #FFFFFF;
  padding: 12px 24px;
  overflow: visible;
}

.domain-testing-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  background: #2A2E3B;
  border-radius: 3px;
  padding: 10px;
  min-width: 360px;
  &.short-tooltip {
    min-width: 50px;
  }
}

.mat-tooltip-panel-below {
  .campaign-tooltip {
    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      background-color: #0c2252;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      left: 47%;
      width: 16px;
      height: 8px;
      bottom: 100%;
    }
  }
}

.mat-tooltip-panel-above {
  .campaign-tooltip {
    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      background-color: #0c2252;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      left: 47%;
      width: 16px;
      height: 8px;
      top: 100%;
    }
  }
}

strong {
  font-weight: 600;
}
