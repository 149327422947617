
.no-margin {
  margin: 0px;
}

.no-padding {
  padding: 0px;
}

.full-stretch {
  width: 100%;
  height: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.clear {
  clear: both;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.font0 {
  font-size: 0px;
}


.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.align-top {
  vertical-align: top;
}

.align-verticalCenter {
  vertical-align: middle;
}

.align-horizontalCenter {
  text-align: center;
}
.padding-10{
  padding: 10px;
}
.padding-20{
  padding: 20px;
}
.padding-30{
  padding: 30px;
}
.padding-sides-10{
  padding:0 10px;
}
.padding-sides-15{
padding:0 15px;
}

.padding-sides-20{
  padding:0 20px;
}

.padding-sides-25{
  padding:0 25px;
}

.padding-right-40 {
  padding-right:40px;
}

.padding-right-20 {
  padding-right:20px;
}

.padding-right-15 {
  padding-right:15px;
}

.padding-top-10{
padding-top:10px;

}
.padding-b-10{
  padding-bottom: 10px;
}
.padding-sides-15{
  padding: 0 15px;

}
.padding-sides-30 {
  padding: 0 30px;
}
.padding-sides-40 {
  padding: 0 40px;
}
.padding-sides-25 {
  padding: 0 25px;
}

.padding-left-20 {
  padding-left:20px;
}
.padding-left-10 {
  padding-left:10px;
}

.padding-top-5 {
  padding-top:5px;
}

.padding-right-10 {
  padding-right: 10px;
}


.margin-sides-15{
  margin-right: 15px;
  margin-left: 15px;
}
.margin-l-25{
  margin-left: 25px;
}
.margin-l-5{
  margin-left: 5px;
}
.margin-l-10 {
  margin-left: 10px;
}
.margin-r-5{
margin-right: 5px;
}

.margin-r-10{
  margin-right: 10px;
}
.margin-r-15{
  margin-right: 15px;
}
.margin-r-20{
  margin-right: 20px;
}
.margin-20{
  margin: 20px;
}
.margin-30{
  margin: 30px;
}
.margin-60{
  margin: 60px;
}
.margin-b-8{
  margin-bottom: 8px;
}
.margin-b-10{
   margin-bottom: 10px;
 }
.margin-b-16{
  margin-bottom: 10px;
}
.margin-b-20{
  margin-bottom: 20px;
}
.margin-b-25{
  margin-bottom: 25px;
}
.margin-b-40{
  margin-bottom: 40px;
}
.margin-t-15{
  margin-top: 15px;
}
.margin-t-20{
  margin-top: 20px;
}
.margin-t-30{
  margin-top: 30px;
}
.margin-t-10{
  margin-top: 10px;
}

.margin-t-5{
  margin-top: 5px;
}
.margin-sides-10{
  margin-left:10px;
  margin-right:10px;
}

.margin-sides-15{
  margin-left:15px;
  margin-right:15px;
}
.margin-sides-20{
  margin-left:20px;
  margin-right:20px;
}
.margin-sides-25{
  margin-left:25px;
  margin-right:25px;
}
.margin-sides-30{
  margin-left:30px;
  margin-right:30px;
}
.margin-sides-40{
  margin-left: 40px;
  margin-right: 40px;
}
.pointer {
  cursor: pointer;
}

.position-right {
  position: absolute;
  right:0px;
}

.padding-vertical-10 {
  padding:10px 0;
}


.flip-vertical {
  &:active{
    transform: scale(1, -1);
  }

}
