.mat-select.mat-select-standalone {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  height: auto;
  width: 230px;

  &.mat-select-disabled {
    opacity: 0.6;
  }

  .mat-select-value {
    padding: 5px 10px;
  }

  .mat-select-value-text {
    color: #0c2252;
    font-size: 16px;
  }

  .mat-select-arrow {
    color: #546e7a !important;
    margin: 0 10px 0 0;
  }
}

.mat-form-field.intercoms-select {
  border-bottom: 1px solid $blue-primary;
  padding-bottom: 4px;
  width: 160px;
  margin-top: 20px;

  .mat-form-field-label-wrapper {
    top: -36px;

    .mat-form-field-label {
      margin-left: 0;
      transform: initial;
      font-size: 14px;
      color: $grey;
    }
  }

  .mat-select-value {
    .mat-select-value-text {
      font-size: 14px;
      color: $blue-primary;
    }
  }

  .mat-select-arrow {
    border: solid $grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: inline-block;
    padding: 3px;
    margin-top: 4px;
  }

  &.mat-form-field-disabled {
    border-bottom-color: $grey-lighter;

    .mat-select-value-text {
      font-size: 14px;
      color: $grey-lighter;
    }

    .mat-select-arrow {
      border-color: $grey-lighter;
    }
  }
}
