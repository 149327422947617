//===  colors ===}


$white :white;
$white-83-opacity:rgba(255, 255, 255, .83);
$white-87-opacity: rgba(255, 255, 255, .87);
$white-18-opacity:rgba(255, 255, 255, .18);

$black: black;

$text-font: Roboto, Arial;

$transparent:transparent;
$modal-backdrop: rgba(0, 0, 0, 0.5);
$app-container-bg:#f6f6f6;
$nav-bar-link: $white;
$nav-bar-active-link: $white;

$header-toolbar-bg :linear-gradient(to right, #6d216f, #5e05aa);
$mat-toolbar-bg :$transparent ;
$txt-color :$white;
$txt-color-default:#1c6ebd;
$txt-color-opacity :  $white-87-opacity;
$user-name-icon-bg :$white-18-opacity;
$separator-background:#f5f5f5;

$user-status-color:  $white-83-opacity;
$user-status-hover-color:  $white-87-opacity;
$user-status-active-color:$white;

$mat-select-bg-color:rgba(255, 255, 255, .18);
$mat-select-value-color:$white;
$mat-select-item-color:#534b5a;
$mat-select-item-active-bg:#f7f2f7;
$mat-select-color: #535353;
$mat-select-border-color: #cbcdcc;

$breadcrumbs-color: #f6f6f6;

$icon-btn-color:rgba(255, 255, 255, .83);
$icon-btn-mo-color:$white-87-opacity;
$icon-btn-active-color:$white;

$text-box-bg:rgba(255, 255, 255, .18);
$text-box-color:$white;
$text-box-placeholder-color:#f6f6f6;

$form-wrapper-bg:$white;

// HEX #00bd31 = rgba(0, 189, 49, 1)
$green: #00bd31;
$green-btn-hover: #00c337;
$green-btn-focused: #01ca3c;
$green-btn-active: #00b129;

// HEX #0078d4 = rgba(0, 120, 212, 1)
$blue: #0078d4;
$blue-secondary: #5995ED;
$blue-btn-hover: #0084d8;
$blue-btn-focused: #0091dc;
$blue-btn-active: #0060cb;

$blue-btn-low-hover: #e6f1fb;
$blue-btn-low-focus: #f2f8fd;
$blue-btn-low-active: #cce4f7;

$blue-btn-md-hover: #deebfa;
$blue-btn-md-focused: #eaf2fb;
$blue-btn-md-active: #bfdef4;

// HEX #f55d3e = rgba(245, 93, 62, 1)
$red: #f55d3e;
$red-btn-hover: #f66746;
$red-btn-focused: #f7714b;
$red-btn-active: #f44c32;
$red-label: #F87060;

// HEX #546e7a = rgba(84, 110, 122, 1)
$grey: #546e7a;
$grey-blue: #DEEAFB;
$grey-btn-focus: #f6f8f8;
$grey-btn-hover: #edf0f1;
$grey-btn-active: #dde2e4;
$grey-border-color: #DDE2E4;
$grey-neutral: #BBC5CA;
$grey-label: #CFD8DC;

$disabled-btn-opacity: 0.3;

$purple-primary: #493C70;
$purple-common: #800080;

$green-primary: #59B198;
$salad-primary: #59B198;
$grey-primary: #546E7A;
$grey-lighter: #98A8AF;
$blue-primary: #0C2252;
$green-label: $green-primary;
$green-label-text: $grey-primary;

$dashboard-btn: $purple-primary;

$dashboard-btn-high-primary: $purple-primary;
$dashboard-btn-high-primary-hover: #50427B;
$dashboard-btn-high-primary-focus: #584886;
$dashboard-btn-high-primary-active: #3A305B;
$dashboard-btn-high-primary-disabled: #493C70;

$dashboard-btn-high-secondary: $green-primary;
$dashboard-btn-high-secondary-hover: $dashboard-btn-high-secondary;
$dashboard-btn-high-secondary-focus: #6BC1AD;
$dashboard-btn-high-secondary-active: #48A183;
$dashboard-btn-high-secondary-disabled: $dashboard-btn-high-secondary;

$dashboard-btn-medium: $purple-primary;
$dashboard-btn-medium-hover: #E8E9EE;
$dashboard-btn-medium-focus: #F3F4F6;
$dashboard-btn-medium-active: #C2C8D4;
$dashboard-btn-medium-disabled: $dashboard-btn-medium;


$dashboard-btn-low-primary: $purple-primary;
$dashboard-btn-low-primary-hover: #E8E9EE;
$dashboard-btn-low-primary-focus: #F3F4F6;
$dashboard-btn-low-primary-active: #CED3DC;
$dashboard-btn-low-primary-disabled: $dashboard-btn-low-primary;

$dashboard-btn-low-secondary: $grey-primary;
$dashboard-btn-low-secondary-hover: #EFF0F2;
$dashboard-btn-low-secondary-focus: #F7F8F8;
$dashboard-btn-low-secondary-active: #DDE2E4;
$dashboard-btn-low-secondary-disabled: $dashboard-btn-low-secondary;

// info-block
$info-block: #F6F5F8;
$info-block-danger: #FFF8F7;

$visited-link: $purple-common;
