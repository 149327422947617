@import "../variables";

// Example:
//<button class="ui-button high secondary">
//  <i class="icon download"></i>
//    Download
//</button>
// Design : https://app.zeplin.io/project/5f3a5476269e358118175a19/screen/5f69e37eac02e31f6a6c99d7


@mixin dashboard-status-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 2px 16px;
  height: 18px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

@mixin dashboard-button {
  color: $white;
  display: flex;
  align-items: center;
  padding: 12px 22px 10px 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.ui-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $white;
  border: 0;
  &:disabled {
    opacity: 0.4;
    color: $white;
    cursor: not-allowed;
  }
  &.high {
    &.primary {
      background-color: $green;
      &:hover {
        background-color: $green-btn-hover;
      }
      &:focus {
        background-color: $green-btn-focused;
      }
      &:active {
        background-color: $green-btn-active;
      }
    }
    &.secondary {
      background-color: $blue;
      &:hover {
        background-color: $blue-btn-hover;
      }
      &:focus {
        background-color: $blue-btn-focused;
      }
      &:active {
        background-color: $blue-btn-active;
      }
    }
    &.warning {
      background-color: $red;
      &:hover {
        background-color: $red-btn-hover;
      }
      &:focus {
        background-color: $red-btn-focused;
      }
      &:active {
        background-color: $red-btn-active;
      }
    }
  }
  &.medium {
    color: $blue;
    border: 1px solid $blue;
    background-color: $white;
    &:hover {
      background-color: $blue-btn-md-hover;
    }
    &:focus {
      background-color: $blue-btn-md-focused;
    }
    &:active {
      background-color: $blue-btn-md-active;
    }
  }
  &.low {
    min-width: 74px;
    border: none;
    background: none;
    &.primary {
      color: $blue;
      &:hover {
        background-color: $blue-btn-low-hover;
      }
      &:focus {
        background-color: $blue-btn-low-focus;
      }
      &:active {
        background-color: $blue-btn-low-active;
      }
    }
    .secondary {
      color: $grey;
      &:hover {
        background-color: $grey-btn-hover;
      }
      &:focus {
        background-color: $grey-btn-focus;
      }
      &:active {
        background-color: $grey-btn-active;
      }
    }
  }

  i {
    padding-right: 5px;
    &.download {
      background: url('../../assets/svg/download-icon.svg') 0 0 no-repeat;
    }
    &.icon {
      background-size: contain;
      width: 18px;
      height: 18px;
    }
  }
}



.dashboard {
  // Toggle button
  &-toggle {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: $green-primary;
      }
      .mat-slide-toggle-thumb {
        background-color: $white;
        box-shadow: 0 0 6px 0 rgba(38, 38, 38, 0.15);
      }
      .mat-slide-toggle-thumb-container {
        transform: translate3d(25px, 0, 0);
      }
    }
    .mat-slide-toggle-thumb-container {
      top: 3px;
      left: 3px;
    }
    .mat-slide-toggle-thumb {
      width: 19px;
      height: 19px;
      background: $white;
    }
    .mat-slide-toggle-bar {
      width: 50px;
      height: 25px;
      background-color: #D3D6D8;
      border-radius: 20px;
    }
    .mat-ripple {
      display: none;
    }
    // Autopilot switcher
    &-autopilot {
      padding-left: 14px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $blue-primary;
      .mat-slide-toggle-bar {
        width: 50px;
        height: 26px;
        background-color: #D3D6D8;
        border-radius: 20px;
      }
      &.off {
        .mat-slide-toggle-bar {
          &:after {
            content: 'OFF';
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $grey-primary;
            opacity: 0.7;
            position: relative;
            top: -1px;
            left: 25px;
          }
        }
      }
      &.mat-checked {
        .mat-slide-toggle-bar {
          background-color: $purple-primary;
        }
        .mat-slide-toggle-thumb {
          background-color: $white;
          box-shadow: 0 0 6px 0 rgba(38, 38, 38, 0.15);
          &:after {
            content: 'ON';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $green-primary;
            opacity: 0.7;
            position: relative;
            top: -4px;
            left: 3px;
          }
          &:before {
            content: '';
            background-image: url('/assets/svg/autopilot_plane.svg');
            height: 16px;
            width: 16px;
            position: absolute;
            left: -20px;
            top: 2px;
          }
        }
      }
    }
  }
  // Buttons
  &-button {
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $dashboard-btn;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    &:hover {
      cursor: pointer;
      background: $dashboard-btn-high-primary-hover;
      color: white;
    }
    &:disabled {
      background: transparent;
      opacity: $disabled-btn-opacity;
    }
    &.confirm {
      background: $dashboard-btn;
      color: $white;
      &:disabled {
        background: $dashboard-btn;
        color: $white;
        opacity: $disabled-btn-opacity;
      }
    }
    &.action {
      border: 1px solid $dashboard-btn;
      display: inline-flex;
      align-items: center;
      &:disabled {
        background: $white;
        border: none;
        opacity: $disabled-btn-opacity;
      }
      &:hover {
        color: #fff;
      }
    }
    &.high {
      &-primary {
        background: $dashboard-btn-high-primary;
        @include dashboard-button;
        &:hover {
          background: $dashboard-btn-high-primary-hover;
        }
        &:focus {
          background: $dashboard-btn-high-primary-focus;
        }
        &:active {
          background: $dashboard-btn-high-primary-active;
        }
        &:disabled {
          background: $dashboard-btn-high-primary-disabled;
          opacity: $disabled-btn-opacity;
          color: $white;
        }
      }
      &-secondary {
        background: $dashboard-btn-high-secondary;
        @include dashboard-button;
        &:hover {
          background: $dashboard-btn-high-secondary-hover;
        }
        &:focus {
          background: $dashboard-btn-high-secondary-focus;
        }
        &:active {
          background: $dashboard-btn-high-secondary-active;
        }
        &:disabled {
          background: $dashboard-btn-high-secondary-disabled;
          opacity: $disabled-btn-opacity;
          color: $white;
        }
      }
    }
    &.medium {
      @include dashboard-button;
      background: $white;
      border: 1px solid $dashboard-btn-medium;
      color: $dashboard-btn-medium;
      &:hover {
        background: $dashboard-btn-medium-hover;
      }
      &:focus {
        background: $dashboard-btn-medium-focus;
      }
      &:active {
        background: $dashboard-btn-medium-active;
      }
      &:disabled {
        background: $white;
        opacity: $disabled-btn-opacity;
        color: $dashboard-btn-medium;
      }
    }
    &.low {
      &-primary {
        @include dashboard-button;
        color: $dashboard-btn-low-primary;
        padding: 11px 12px 12px 16px;
        &:hover {
          background: $dashboard-btn-low-primary-hover;
        }
        &:focus {
          background: $dashboard-btn-low-primary-focus;
        }
        &:active {
          background: $dashboard-btn-low-primary-active;
        }
        &:disabled {
          background: $white;
          opacity: $disabled-btn-opacity;
          color: $dashboard-btn-low-primary-disabled;
        }
      }
      &-secondary {
        @include dashboard-button;
        color: $dashboard-btn-low-secondary;
        padding: 11px 12px 12px 16px;
        &:hover {
          background: $dashboard-btn-low-secondary-hover;
        }
        &:focus {
          background: $dashboard-btn-low-secondary-focus;
        }
        &:active {
          background: $dashboard-btn-low-secondary-active;
        }
        &:disabled {
          background: $white;
          opacity: $disabled-btn-opacity;
          color: $dashboard-btn-low-secondary-disabled;
        }
      }
    }
    svg, img {
      margin-right: 8px;
    }
  }
  // Status-bar
  &-status-bar {
    &-green {
      @include dashboard-status-bar;
      background: $green-primary;
      color: $white;
    }
    &-salad {
      @include dashboard-status-bar;
      background: #DEEFEA;
      color: $salad-primary;
    }
    &-grey {
      @include dashboard-status-bar;
      background: #98A8AF;
      color: #fff;
    }
    &-orange {
      @include dashboard-status-bar;
      background: #F87060;
      color: $white;
    }
    &-purple {
      @include dashboard-status-bar;
      background: #6D638D;
      color: $white;
    }
    &-light-blue {
      @include dashboard-status-bar;
      background: #BDD5F8;
      color: $white;
    }
  }
  // Checkboxes
  &-checkbox {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $grey-primary;
    .mat-checkbox-inner-container {
      margin-right: 8px;
    }
    &.mat-checkbox-checked:not(.mat-checkbox-disabled) {
      .mat-checkbox-background {
        background-color: $purple-primary;
      }
    }
    &.mat-checkbox-indeterminate, .mat-checkbox-checked:not(.mat-checkbox-disabled) {
      .mat-checkbox-background {
        background-color: $purple-primary;
      }
    }
    &.mat-checkbox-indeterminate.mat-checkbox-disabled, .mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: #b0b0b0;
      }
    }
  }
  // Table line
  &-table-line {
    width: 100%;
    height: 50px;
    background: $white;
    display: flex;
    align-items: center;
    padding-left: 22px;
    margin-left: -22px;
    padding-right: 26px;
    margin-right: -26px;
    border-left: 4px solid $white;
    border-bottom: 1px solid #D5DDE0;
    &:hover {
      cursor: pointer;
      background: #F2F2F2;
      .dashboard-table-line-label {
        color: $blue-primary;
      }
    }
    &:active {
      background: #F6F9FD;
    }
    &-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $grey-primary;
    }
    &.selected {
      border-left: 4px solid $purple-primary;
      &.with-toggle:not(:hover) {
        background: #F6F9FD;
      }
      .dashboard-table-line-label {
        color: $blue-primary;
      }
    }
    .dashboard-toggle {
      margin-left: auto;
    }
  }
}

.dots-default-color {
  color: #546E7A;
  &:hover {
    color: #493C70;
  }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fff;
}
.mat-mdc-select-arrow {
  left: 10px;
}
