@use '@angular/material' as mat;
@import "../styles/variables";
/*@import "../../styles/material.theme";
@import '@angular/material/theming';*/

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/*TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/*TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

:root {
  --mat-select-panel-background-color: #fff;
  --mat-menu-container-color: #fff;
  --mat-option-selected-state-layer-color: #F7FAFE;
  --mat-option-selected-state-label-text-color: #0C2252;
  --mat-option-label-text-color: #546E7A;
  --mat-select-placeholder-text-color: #98A8AF;
  --mat-option-hover-state-layer-color: rgba(245, 245, 245, 0.8);
  --mat-select-enabled-trigger-text-color: #0C2252;
  --mat-select-trigger-text-size: 14px;
  --mdc-typography-font-family: 'Roboto', sans-serif;
  --mdc-typography-button-font-family: 'Roboto', sans-serif;
  --mdc-typography-button-letter-spacing: 0;
  --mdc-dialog-supporting-text-font: 'Roboto', sans-serif;
  --mdc-dialog-supporting-text-tracking: 0;
  --mat-datepicker-calendar-date-selected-state-background-color: #493C70;
  --mat-datepicker-toggle-active-state-icon-color: #493C70;
}
$mat-ui-light-blue: (
  50 : #f6f6f8,
  100 : #bbd4eb,
  200 : #8eb7de,
  300 : #609ad1,
  400 : #3e84c7,
  500 : #1c6ebd,
  600 : #1966b7,
  700 : #145bae,
  800 : #1151a6,
  900 : #093f98,
  A100 : #c6d8ff,
  A200 : #93b6ff,
  A400 : #6093ff,
  A700 : #4782ff,
  contrast: (
    50 : #534b5a,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);
$mat-ui-red: (
  50 : #fce4e6,
  100 : #f7bcc1,
  200 : #f18f97,
  300 : #eb626d,
  400 : #e7414e,
  500 : #e31f2f,
  600 : #e01b2a,
  700 : #dc1723,
  800 : #d8121d,
  900 : #d00a12,
  A100 : #fffbfb,
  A200 : #ffc8c9,
  A400 : #ff9597,
  A700 : #ff7b7f,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$mat-ui-orange: (
  50 : #ffeee0,
  100 : #fed4b3,
  200 : #feb880,
  300 : #fe9c4d,
  400 : #fd8626,
  500 : #fd7100,
  600 : #fd6900,
  700 : #fc5e00,
  800 : #fc5400,
  900 : #fc4200,
  A100 : #ffffff,
  A200 : #fff2ef,
  A400 : #ffc9bc,
  A700 : #ffb5a2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$mat-ui-purple1: (

  50 : #ede4ee,
  100 : #d3bcd4,
  200 : #b690b7,
  300 : #99649a,
  400 : #834285,
  /*500 : #6d216f,*/
  500 : #6d216f,
  600 : #651d67,
  700 : #5a185c,
  800 : #501452,
  900 : #3e0b40,
  A100 : #f979ff,
  A200 : #f746ff,
  A400 : #f413ff,
  A700 : #ed00f8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);
$mat-ui-purple2: (

  50 : #f6f6f8,
  100 : #af82d5,
  200: #8f50c4,
  300 : #7e35b4,
  400 : #772bb8,
  500 : #5f05ab,
  600 : #5704a4,
  700 : #4d049a,
  800 : #430391,
  900 : #320180,
  A100 : #c6adff,
  A200 : #a37aff,
  A400 : #8047ff,
  A700 : #6e2eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


@mixin initTheme($theme) {
  $ui-theme-primary: mat.define-palette($theme);
  $ui-theme-accent: mat.define-palette($theme, A200, A100, A400);

  $ui-theme: mat.define-light-theme($ui-theme-primary, $ui-theme-accent);
  /*TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-legacy-component-themes($ui-theme);
  @include mat.all-component-themes($ui-theme);
}

body.cyber-theme{
  $ui-theme-primary: mat.define-palette($mat-ui-light-blue);
  $ui-theme-accent: mat.define-palette($mat-ui-light-blue, A200, A100, A400);
  @include initTheme($mat-ui-light-blue);
  @import "controls";
}

div.cellcom-theme{
  $ui-theme-primary: mat.define-palette($mat-ui-light-blue);
  $ui-theme-accent: mat.define-palette($mat-ui-light-blue, A200, A100, A400);
  @import "controls";
}

div.partner-theme{
  $ui-theme-primary: mat.define-palette($mat-ui-orange);
  $ui-theme-accent: mat.define-palette($mat-ui-red, A200, A100, A400);
  @include initTheme($mat-ui-orange);
  @import "controls";
}

.mat-card,
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-toolbar,
.mat-slide-toggle-content{
  font-family: Roboto, Arial;
}

.mat-card-header .mat-card-title {
    font-size: 21px;
}
