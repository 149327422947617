@import 'variables';

.preview-panel-header {
  height: 58px;
  padding: 14px 6px 14px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;

  h2 {
    color: $blue-primary;
  }

  .right {
    min-width: 300px;
  }

  .left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      color: $grey-primary;

      .campaign-type {
        margin-left: 9px;
      }

      label {
        color: $blue-primary;
        margin-left: 12px;
      }
    }

    .cog {
      display: inline-block;
      margin: 4px 0 1.5px 8px;
      object-fit: contain;
      border: solid 1px var(--0078-d-4-2);

      img {
        position: relative;
        top: 3px;
      }
    }
  }
}

.preview {
  height: 100%;
  display: flex;
  flex-direction: column;


  .preview-panel-header {

    .right {
      flex-direction: row;
    }

    .preview-language {
      margin-left: auto;
    }
  }

  .top-header {
    border-top: solid 1px $grey-border-color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-left: solid 1px $grey-border-color;
    border-right: solid 1px $grey-border-color;

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid $grey-border-color;
      padding: 4px 120px 2px 22px;
      position: relative;
      min-height: 39px;

      span {
        color: $grey-primary;
        font-size: 14px;

        &.sub-header {
          color: $grey-lighter;
          font-size: 10px;
          line-height: 14px;
          display: inline-block;
        }
      }

      .action {
        color: $dashboard-btn-high-primary-disabled;
        font-weight: normal;
        line-height: normal;
        position: absolute;
        border-radius: 4px;
        top: 50%;
        margin-top: -13px;
        right: 12px;
        background: url("/assets/svg/envelope.svg") 4px 50% no-repeat;
        padding: 0 0 0 16px;
        width: 90px;
        height: 26px;

        &:hover {
          font-weight: 500;
        }

        &:active {
          text-decoration: underline;
        }
      }

      .subject {
        cursor: pointer;
        position: relative;

        &:hover {
          outline: green solid 1px;

          &:before {
            line-height: 15px;
            content: 'EDIT';
            color: $white;
            background: green;
            position: absolute;
            font-size: 10px;
            top: -1.5em;
            left: -4px;
            width: 30px;
            text-align: center;
            padding: 1px 2px;
          }
        }

        &.rtl {
          unicode-bidi: embed;
        }
      }

      .rtl {
        direction: rtl;
        font-family: Arimo, Arial;
      }

      .display-name, .login-name, .sender-name {
        cursor: pointer;
        position: relative;
        border: solid transparent;
        &:hover {
          outline: green solid 1px;

          &:before {
            line-height: 15px;
            content: 'EDIT';
            color: #fff;
            background: green;
            position: absolute;
            font-size: 10px;
            top: -1.5em;
            left: -4px;
            width: 30px;
            text-align: center;
            padding: 1px 2px;
          }
        }
      }

      .display-name {
        &:hover {
          outline: #FF7F27 solid 1px;
        }
      }

      .login-name {
        &:hover {
          outline: #FF7F27 solid 1px;
        }
      }
    }
  }

  .content {
    border: 1px solid $grey-border-color;
    border-top: 0;
    flex-grow: 1;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
