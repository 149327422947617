@use '@angular/material' as mat;

.user-menu {
  .mat-menu-item {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
  }
}

.header {

  mat-toolbar {
    .main-tabs {
      &, &:hover, &.active, & i {
        color: mat.get-contrast-color-from-palette($ui-theme-primary, 500);
      }
    }
  }
}
