.cdk-overlay-pane.scorecard-dialog {
  .content-wrp {
    .mat-dialog-title {
      margin-top: 0;
      font-weight: 500;
    }

    .mat-dialog-content {
      text-align: center;
      margin-top: 10px;
      padding: 0 60px;
    }
  }

  .mat-dialog-actions {
    .dashboard-button.action {
      border: none;

      &:hover, &:focus {
        background: none;
        color: initial;
      }
    }
  }
}
