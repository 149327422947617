@use '@angular/material' as mat;

.txt {
  color: mat.get-color-from-palette($ui-theme-primary, 500);
}

:host {
  .welcome-bg {
    .welcome-div {
    }
    @media only screen and (max-width: 500px) {
    }
  }

  .form-wrapper {
    .login-header {
      color: mat.get-color-from-palette($ui-theme-primary, 500);
      @media only screen and (max-width: 500px) {

      }

    }
    form {
      background: mat.get-contrast-color-from-palette($ui-theme-primary, 500);

      .field-wrapper {
        .field {
          @media only screen and (max-width: 500px) {
          }
        }
      }
      .bottom-section {
        @media only screen and (max-width: 500px) {
        }
        .forgot-pass {
          color: mat.get-contrast-color-from-palette($ui-theme-primary, 50);
          @media only screen and (max-width: 500px) {

          }

        }
        button {

        }
      }
    }
    .mobile-display-logo {
      @media only screen and (max-width: 500px) {
      }

    }
    @media only screen and (max-width: 500px) {
    }
  }

  input {
    background: mat.get-color-from-palette($ui-theme-primary, 50);
    color: mat.get-color-from-palette( $ui-theme-primary,500);

  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px mat.get-color-from-palette($ui-theme-primary, 50) inset;

  }

  input:-webkit-autofill {
    -webkit-text-fill-color: mat.get-color-from-palette($ui-theme-primary, 500) !important;

  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: mat.get-color-from-palette($ui-theme-primary, 200);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: mat.get-color-from-palette($ui-theme-primary, 200);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: mat.get-color-from-palette($ui-theme-primary, 200);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: mat.get-color-from-palette($ui-theme-primary, 200);
  }

}

.form-controls-wrapper {
  border: 1px solid #98A8AF;
  border-radius: 4px;
  width: fit-content;
  app-form-control-text, app-form-control-dropdown, app-form-control-chips {
    &:first-of-type {
      .form-control {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    &:last-of-type {
      .form-control {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

  }

  .border-bottom-none{
      .form-control {
        border-bottom: none;
      }
  }

  .add-top-border {
    .form-control {
      border-bottom: 1px solid #98A8AF !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .add-borders {
    .form-control {
      border-bottom: 1px solid #98A8AF !important;
      border-top: 1px solid #98A8AF !important;
      border-radius: 0 !important;;
    }

  }
}

.form-control {
  display: flex;
  border-bottom: 1px solid #98A8AF;
  align-items: center;
  padding: 0 10px;
  min-height: 40px;
  background: #fff;
  width: 100%;

  &:hover {
    background: #F7FAFE;
    .clear {
      visibility: visible;
    }
    .edit {
      visibility: visible;
    }
  }
  .icon {
    max-width: 20px;
    max-height: 20px;
    &-wrapper {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      width: 20px;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #546E7A;
    min-width: 192px;
    margin-right: 20px;
  }
  .value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #0C2252;
    background: transparent;
    input {
      border: none;
      &::placeholder{
        color: #98A8AF;
      }
      &:disabled {
        background: transparent;
      }
    }
  }
  .edit {
    position: relative;
    visibility: hidden;
    top: 2px;
  }
  .chips-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    padding: 6px 0;
    margin-bottom: 2px;
  }
  .chip {
    background: #5995ED;
    color: #fff;
    display: flex;
    margin: 2px 6px;
    margin-left: 0;
    padding: 2px 14px;
    border-radius: 12px;
    align-items: center;
  }
  .chips-select {
    display: none;
  }
  .chips-placeholder {
    text-decoration: underline;
    color: #5995ED;
    align-self: center;
    margin-top: 6px;
    min-width: 120px;
  }
  .close-icon {
    margin-right: 5px;
  }
  .clear {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: #98A8AF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    margin-left: auto;
    visibility: hidden;
  }
}

.text-input-control {
  border: none;
  outline: none;
  height: 22px;
  padding: 0 4px;
  color: #0C2252;
  font-size: 14px;
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    font-family: Roboto;
    width: 100%;
    height: 58px;
    input {
      border-bottom: 1px solid #98A8AF;
      width: 100%;
    }
    &.control-not-valid {
      input {
        border-bottom: 1px solid #FF0000;
        margin-bottom: 6px;
      }
    }
    .error-message {
      color: #F87060;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
    .label {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #98A8AF;
      height: 14px;
      margin-bottom: 4px;
    }
  }




}
