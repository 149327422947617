/* You can add global styles to this file, and also import other style files */
@import "node_modules/compass-sass-mixins/lib/compass";
@import "styles/material.theme";
@import "styles/dialog-customization";
@import "styles/flexbox";
@import "styles/helper";
@import "styles/reset";
@import "styles/variables";
@import "shared-components/preview_panel_header";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");


@font-face {
  font-family: arimo;
  src: url("/assets/font/arimo.woff") format("woff"),
  url("/assets/font/arimo.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url("/assets/font/inter/Inter-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url("/assets/font/inter/Inter-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url("/assets/font/inter/Inter-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url("/assets/font/inter/Inter-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto';
  src: url("/assets/font/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/font/poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/font/poppins/Poppins-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/font/poppins/Poppins-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

mat-accordion {

  line-height: 2;
 /* color: mat-color($ui-theme-primary, 500);*/
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding: 0px 16px;
      font-size: 18px;
      min-height: 45px;
      font-weight: bold;
      font-family: $text-font;

      .mat-expansion-indicator {
        display: none;
      }

      .mat-content {
        align-items: center;
      }
    }
  }
}

.mat-checkbox {
  &.mat-checkbox-indeterminate {
    .mat-checkbox-inner-container {
      .mat-checkbox-background {
        border-radius: 3px;

        .mat-checkbox-mixedmark {
          width: calc(100% - 6px);
        }
      }
    }
  }

}

.mat-form-field {
  font-size: 12px;
  padding: 0px;
  border: 0px;
  width: auto;
  min-width: 50px;

  label {
    color: white;
    font-size: 12px;
    font-family: $text-font;
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-labeler {
          position: absolute;
          left: 8px;
          top: -10px;

        }

        .mat-form-field-labelwrapper {
          .mat-form-field-label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .mat-form-field-wrapper {
    padding: 0px;

    .mat-form-field-flex {
      padding: 0px;

      .mat-form-field-flex {
        padding: 0px;

        .mat-form-field-infix {
          .mat-form-field-infix {
          }
        }
      }

      .mat-form-field-infix {
        padding: 0px;
        border: 0px;
      }

      input.mat-input-element {

      }
    }

    .mat-form-field-underline {
      height: 0px;

      .mat-form-field-ripple {
        background-color: transparent;
      }
    }
  }
}

.mat-select {
  &.customer-switch {
    width: calc(100% - 16px);
    padding: 0 8px;
  }

  .mat-select-value {
    vertical-align: middle;
    font-size: 12px;
    font-family: $text-font;
    /*  color: mat-contrast($ui-theme-primary, 400);*/

  }

  height: 24px;
  width: 100%;
  border-radius: 2px;
  /*background-color: $mat-select-bg-color;*/
  .mat-select-arrow-wrapper {
    height: 25px;

    .mat-select-arrow {
      /*color: mat-contrast($ui-theme-primary, 400);*/
      &:active {
        /*color: mat-contrast($ui-theme-primary, 400);*/
      }
    }
  }
}

html, body {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: $text-font;
}

.mat-select-panel {
  border-radius: 2px;

  .mat-select-content {
    .mat-option {
      line-height: 28px;
      height: 28px;
      font-size: 12px;

      .mat-active {
      }
    }
  }
}

body.cyber-theme .cdk-overlay-pane {
  font-family: Roboto, "Helvetica Neue", sans-serif;

  .mat-dialog-container {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    color: #546e7a;
  }

  .mat-mdc-dialog-surface {
    padding: 24px;
  }

  .dialog-close{
    background: url("assets/svg/close-dialog.svg") top right no-repeat;
    width: 100%;
    height: 14px;
    cursor: pointer;
  }

  .mat-dialog-title {
    color: #0C2252;
    font-size: 22px;
    line-height: 1.14;
    font-weight: 500;

    &.no-transform{
      text-transform: initial;
    }
  }

  .mat-dialog-content {
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  .mat-dialog-actions {
    padding: 18px 0;
  }
  .mat-mdc-dialog-actions {
    justify-content: flex-end;
  }
  .delivery-settings-select {
    min-width: 100% !important;
    position: relative;
    left: 5%;
    top: 15%;

    .searchbox-wrp {
      padding: 0 15px;

      .searchbox {
        align-items: center;
        border: 1px solid #cfd8dc;;
        border-radius: 4px;
        padding: 5px 8px 5px 12px;
        margin: 16px 0;

        input {
          font-size: 14px;
          font-style: italic;
          border: none;
          outline: none;
          width: 115px;
          font-family: Roboto, "Helvetica Neue", sans-serif;
          margin-left: 5px;
          color: #546e7a;
        }

        .search-icon {
          background: url("assets/svg/search-icon.svg") center no-repeat;
          width: 16px;
          height: 16px;
          display: inline-block;
          vertical-align: bottom;
        }
      }
    }

    .mat-option {
      line-height: 35px;
      height: 35px;
      font-size: 14px;
      color: #546e7a;
      &.mat-selected {
        color: #0C2252;
      }
      &.mat-active {
        color: #0C2252;
        background-color: #f6f9fd;
      }
    }
    &.delivery-settings-multi-select {
      left: 13%;
    }
    &.delivery-settings-hours {
      left: 12%;
    }
  }
}

.mat-pseudo-checkbox-checked {
  background-color: #493C70 !important;
}

.bu-report-select {
  margin-top: 30px !important;
}

.dashboard-chips {

  .dashboard-chips-form {
    width: 100%;

    input#email-input {
      font-size: 14px;

      &::placeholder {
        color: #546E7A;
        -webkit-text-fill-color: #546E7A;
      }
    }

    .mat-form-field-wrapper {
      .mat-form-field-underline {
        .mat-form-field-ripple {
          top: 20px;
        }
      }

      .mat-form-field-label-wrapper {
        display: none;
      }
    }

    .mat-form-field-label {
      transform: none;
    }
  }
}


.cp-dialog-container .mat-dialog-container{
  padding-top: 15px;
}

#guidde-sdk {
  g-btn {
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 14px 0px;
  }
}

.language-changes {
  margin-top: 8px;
  background-color: #F2F8FD;
  border-radius: 2px;
  padding: 8px;
  p {
    margin-left: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #0C2252;
  }
}

.poster-studio-dialog {
  max-width: unset !important;
}

.dashboard-filter-dialog-container {
  min-height: 324px;
  max-height: 94vh;

  .mat-dialog-container {
    padding: 0;
    height: auto;
  }
}

.hq-tooltip {
  min-width: 300px;
  max-width: 500px;
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  span {
    text-align: left;
  }
}

.info-block {
  background: url('/assets/svg/info-icon.svg') 14px 50% no-repeat, $info-block;
  padding: 10px 30px 10px 48px;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;

  &.danger {
    background-color: $info-block-danger;
    background-image: url('assets/svg/info-icon-danger.svg');
  }
}

.progress-bar-tooltip {
  min-width: 100px;
  max-width: 300px;
  &-container {
    display: flex;
    margin-bottom: 4px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.dashboard-kebab-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  img {
    margin-right: 8px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey-primary;
  }
}

.arrow-icon {
  position: relative;
  bottom: 1px;
  &.down {
    transform: rotateZ(180deg);
  }
}

.bold {
  font-weight: bold;
}

.echarts-tooltip-dark {
  background: $blue-primary !important;
  min-width: 200px;
  color: $white !important;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
      font-weight: bold;
    }
    .title {
      font-style: italic;
    }
  }
}

.content-hidden {
  visibility: hidden;
}

.mat-snack-bar-container.login-snackbar, .mat-mdc-snack-bar-container.login-snackbar {
  background: #FFFFFF !important;
  color: $blue-primary !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.top-bar {
  justify-content: flex-start;
  padding-left: 40px;
  padding-bottom: 20px;
  padding-top: 30px;
  border-bottom: 1px solid #B6B1C6;
  h1 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.89px;
    text-align: left;
    color: #0C2252;
  }
  h2 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.0075em;
    text-align: left;
    color: #546E7A;
  }
}

.announcement-dialog .mat-dialog-container {
  padding: 0;
  min-width: 854px;
  min-height: 497px;
}
.form-control-dropdown {
  min-width: 300px;
}

.custom-snackbar-container {
  .mdc-snackbar__surface  {
    padding-right: 0;
  }
  .mat-mdc-snack-bar-label {
    box-shadow: none;
    padding: 0;
  }
}

