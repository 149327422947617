
// UI flexbox layout model
// Current implementation is covering the basics of flexbox in order to support our browser compatibility requirement.

// Flexbox Containers
//
// The 'flex' value causes an element to generate a block-level flex
// container box.
//
// The 'inline-flex' value causes an element to generate a inline-level
// flex container box.
//
// display: flex | inline-flex
// supported: flex
//
// http://w3.org/tr/css3-flexbox/#flex-containers
.ui-layout {
  display: flex;
  position: relative;
}

// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// supported : row | column
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property
.ui-layout-row {
  flex-direction: row;
  @extend .ui-layout;
  &.half{
    -webkit-flex: 1 0 0;
    flex: 1 0 0;
  }
}

.ui-layout-row-reverse {
  flex-direction: row-reverse;
  @extend .ui-layout;
}

.ui-layout-column {
  flex-direction: column;
  @extend .ui-layout;
  &.half{
    -webkit-flex: 1 0 0;
    flex: 1 0 0;
  }
}


// Flexbox Flex
// The flex property specifies the components of a flexible length: the flex grow factor and flex shrink factor, and the flex basis.
// When a box is a flex item, flex is consulted instead of the main size property to determine the main size of the box. If a box is not a flex item, flex has no effect.
.ui-layout-flex {
  flex: 1;
  -ms-flex: 1;
  position: relative;
}
.ui-layout-flex-2 {
  flex: 2;
  -ms-flex: 1;
  position: relative;
}
.ui-layout-flex-3 {
  flex: 3;
  -ms-flex: 1;
  position: relative;
}

.ui-flex-bases-auto {
  flex-basis: auto;
}

.ui-layout-fill {
  min-height: 100%;
}

// Alignment attributes for layout containers' children
// Arrange on the Main Axis
// center, start, end, space-between, space-around
// flex-start is the default for justify-content
// ------------------------------

// Main Axis Center
[ui-layout-align="center"], //stretch
[ui-layout-align="center center"],
[ui-layout-align="center start"],
[ui-layout-align="center end"] {
  justify-content: center;
}


// Main Axis End
[ui-layout-align="end"], //stretch
[ui-layout-align="end center"],
[ui-layout-align="end start"],
[ui-layout-align="end end"] {
  justify-content: flex-end;
}

// Main Axis Space Around
[ui-layout-align="space-around"], //stretch
[ui-layout-align="space-around center"],
[ui-layout-align="space-around start"],
[ui-layout-align="space-around end"] {
  justify-content: space-around;
}

// Main Axis Space Between
[ui-layout-align="space-between"], //stretch
[ui-layout-align="space-between center"],
[ui-layout-align="space-between start"],
[ui-layout-align="space-between end"] {
  justify-content: space-between;
}


// Arrange on the Cross Axis
// center, start, end
// stretch is the default for align-items
// ------------------------------

// Cross Axis Center
[ui-layout-align="center center"],
[ui-layout-align="start center"],
[ui-layout-align="end center"],
[ui-layout-align="space-between center"],
[ui-layout-align="space-around center"] {
  align-items: center;
}

// Cross Axis Start
[ui-layout-align="center start"],
[ui-layout-align="start start"],
[ui-layout-align="end start"],
[ui-layout-align="space-between start"],
[ui-layout-align="space-around start"] {
  align-items: flex-start;
}

// Cross Axis End
[ui-layout-align="center end"],
[ui-layout-align="start end"],
[ui-layout-align="end end"],
[ui-layout-align="space-between end"],
[ui-layout-align="space-around end"] {
  align-items: flex-end;
}


.ui-layout-stretch {
  align-items: stretch;
}

.ui-layout-space-around{

  justify-content: space-around;
}

.ui-layout-space-between{
  justify-content: space-between;
}

.align-center{
  align-items: center;
}

.ui-flex-half{
  width: 50%;
}

.ui-flex-full{
  bottom: 0;
  top: 0;
  position: absolute!important;
  left: 0;
  right: 0;
}

.ui-flex-wrap{
  flex-wrap: wrap;
}


.ui-align-item-end{
  align-items: flex-end;
}

.ui-align-item-start{
  align-items: flex-start;
}
